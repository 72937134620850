/* Theme Colors */
:root {
    --theme-bg: #E8E5DE;
    --theme: hsl(0, 0%, 0%);
    --theme-dark: hsl(0, 0%, 22%);
    --theme-transparent: rgba(0, 0, 0, 0.5);
}

*::-webkit-scrollbar {
    display: none;
}